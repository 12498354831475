@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../wdx/mixins/typography';
@import './variables';

.podSectionAttributes {
  @extend %pod-section;
  margin-top: 20px;

  &.hideTags{
    margin-top: 0; 
  }

  .fullSize & {
    padding: {
      left: $product-pod-horizontal-padding;
      right: $product-pod-horizontal-padding;
    }
  }
}

.productPod {
  display: none;
  font-family: Waitrose;
  margin-bottom: 16px;
  position: relative;
  
  .wrapper {
    position: relative;
  }
}

.promotion {
  @extend %pod-section;

  @include media-breakpoint-up('sm') {
    .isEntertaining & {
      padding-bottom: $grid-vertical-gutter;
    }
  }
}

.fullSize {
  composes: productPod;
  height: 100%;
  margin: 0;
  padding: 0;
}

.noDeviceBreakpoints {
  display: flex;
}

.details {
  display: flex;
  flex-direction: row;
  font-size: $font-waitrose-body-size-small;
  max-width: 100%;
  padding: $grid-vertical-gutter $grid-vertical-gutter 0;
  position: relative;
  width: 100%;

  .fullSize & {
    flex-direction: column;
    padding: 30px 0 10px;
  }

  @include media-breakpoint-up('sm') {
    flex-direction: column;
    padding: 30px 0 0;
    flex-grow: 0;
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.content {
  background: $colour-white;
  text-align: left;
  width: 100%;
  border: 1px solid $colour-oyster-grey;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &.inTrolley {
    &::before {
      box-shadow: inset 0 0 0 2px $colour-success-green;
      content: ' ';
      height: 100%;
      position: absolute;
      top: 0;
      left: 8px;
      width: calc(100% - 16px); // less 8px padding from left/right
    }

    &::after {
      bottom: 0;
      content: ' ';
      height: 4px;
      left: 8px;
      position: absolute;
      width: calc(100% - 16px);
      background-color: $colour-success-green;
    }
  }

  .fullSize & {
    border: 0;
    display: flex;

    @include media-breakpoint-up('sm') {
      margin: 0;
    }

    &.inTrolley {
      &::before {
        content: ' ';
        box-shadow: inset 0 0 0 2px $colour-success-green;
        height: auto;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &::after {
        content: ' ';
        background-color: $colour-success-green;
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  @include media-breakpoint-up('sm') {
    display: flex;
    text-align: left;
    justify-content: space-between;
  }
}

.starRating {
  line-height: 1;
  margin: {
    bottom: 0;
    top: $grid-vertical-gutter * 0.5;
  }

  .fullSize & {
    padding: {
      left: $product-pod-horizontal-padding;
      right: $product-pod-horizontal-padding;
    }
  }
}

.conflict {
  @include paragraph('medium');
  border-top: 1px solid $colour-oyster-grey;
  padding: $ingredients-unit * 3;
  width: 100%;
}

.icons {
  align-items: center;
  display: inline-grid;
  grid-gap: 10px;
  grid-template-columns: auto 30px;
  justify-items: flex-end;
  padding-left: 1px;
  pointer-events: none;
  position: absolute;
  left: 8px;
  width: calc(100% - 16px);
  z-index: 1;

  button {
    pointer-events: auto;
  }

  .badgeContainer {
    display: flex;
    justify-self: flex-start;
    margin-top: 1px;
  }

  &.hasBadge {
    .badgeContainer {
      display: flex;
      width: 100%;
    }
  }

  &.activeIcons {
    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  .fullSize & {
    width: 100%;
    left: 0;
    padding-left: 0;
  }
}

.desktopCounter {
  display: none;

  @include media-breakpoint-up('sm') {
    display: block;
    position: absolute;
    top: -60px;
    transition: 0.25s;
    transition-timing-function: ease-in;
    width: 100%;

    &.desktopCounterActive {
      top: 0;
      transition: 0.25s;
      transition-timing-function: ease-out;
      z-index: 1;
    }

    &.optionsOpen {
      height: 150px;
    }

    .fullSize & {
      width: initial;
      left: 1px;
      right: 1px;

      [class^='productPodCounter'] {
        border-top-width: 2px;
      }
    }
  }
}

.mobileCounter {
  display: flex;
  flex-direction: column;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  width: 100%;
  z-index: 100;

  @include media-breakpoint-up('sm') {
    display: none;
  }

  &.mobileCounterActive {
    border-bottom: 1px solid $colour-success-green;
    border-left: 1px solid $colour-success-green;
    visibility: visible;
    max-height: 341px;
    z-index: 100;

    &.noSlidedown {
      height: 42px;
    }

    .fullSize & {
      border: 0;
    }
  }

  .fullSize & {
    width: calc(100% - 4px);
    margin-left: 1px;
  }
}

.trolleyChoices {
  align-items: flex-end;
  border-top: 0;
  clear: initial;
  display: flex;
  flex: {
    direction: row;
    wrap: nowrap;
  }
  justify-content: space-between;
  margin-top: auto;
  padding: 0 $grid-vertical-gutter $grid-vertical-gutter;
  width: 100%;
  flex-grow: 0;
  z-index: 10;

  @include media-breakpoint-up('sm') {
    align-items: normal;
    border-top: 0;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: initial;
    padding: $grid-vertical-gutter $grid-vertical-gutter $grid-vertical-gutter;
  }
}

.fullSizeTrolleyControls {
  composes: trolleyChoices;
  background: none;
  padding: $grid-vertical-gutter;

  @include media-breakpoint-up('sm') {
    border-top: 0;
    flex-direction: row;
    justify-content: space-between;
  }
}

.controls {
  width: 100%;
  z-index: 1; // the green border ::before was blocking the interaction with input field

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .fullSize & {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0;
  }
}

.headerStarWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @include media-breakpoint-up('sm') {
    margin-bottom: 0;
  }
}
